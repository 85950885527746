import { PURCHASE_ORIGINS } from '../constants';

export function shouldPreventStandardPurchase(params, options) {
  const { origin } = params;
  const [isStores, isBookings, isEvents] = [
    origin === PURCHASE_ORIGINS.STORES,
    origin === PURCHASE_ORIGINS.BOOKINGS,
    origin === PURCHASE_ORIGINS.WIX_EVENTS,
  ];
  return isStores
    ? options.useStoresPurchaseFBServerEvent && !params.id
    : isBookings
    ? options.useBookingsPurchaseFBServerEvent
    : isEvents
    ? options.useEventsPurchaseFBServerEvent
    : false;
}
