import { paramsMapper } from '../params-mapper';

/*
 * Adapter for Google Tag Manager
 */
/* eslint-disable camelcase */
const channelParams = {
  type: 'type', // type (string)
  price: 'price', // Value of a user performing this event to the business
  sku: 'sku', // Product stock keeping unit id
  id: 'id', // Product ID (string)
  currency: 'currency', // The currency for the price specified
  name: 'name', // Name of the page/product
  category: 'category', // Category of the page/product
  brand: 'brand', // Product brand (string)
  variant: 'variant', // Product variant (string)
  list: 'list', // Name of the list the product was selected from, e.g 'search results'
  quantity: 'quantity', // Product quantity (number)
  step: 'step', // Step number for the checkout action
  option: 'option', // Additional info about the checkout action e.g. Visa/Mastercard, FedEx/USPS
  position: 'position', // Position  (string)
  coupon: 'coupon', // Product coupon (string).
  affiliation: 'affiliation', // Affiliation (string).
  revenue: 'revenue', // Revenue (currency).
  tax: 'tax', // Tax (currency).
  shipping: 'shipping', // Shipping (currency).
  buyerMail: 'buyer_email', // buyer's email address (value should be hashed).
  buyerId: 'buyer_id', // buyer's ID (value should be hashed).
};

export const channelEvents = {
  PageView: (
    { pagePath, pageTitle: title } = {},
    { reportToManagedChannels } = {},
  ) =>
    reportToManagedChannels
      ? [
          [
            [
              {
                event: 'Pageview',
                url: pagePath,
                title,
              },
            ],
          ],
        ]
      : [],
  AddProductImpression: ({ contents, origin }) => {
    const impressions = contents;
    return [
      [
        [
          {
            event: 'productImpression',
            ...(origin && { origin }),
            ecommerce: { impressions },
          },
        ],
      ],
    ];
  },
  ClickProduct: ({ list = '(not set)', origin, ...params }) => {
    return [
      [
        [
          {
            event: 'productClick',
            ...(origin && { origin }),
            ecommerce: {
              click: {
                actionField: { list },
                products: [paramsMapper(params, channelParams)],
              },
            },
          },
        ],
      ],
    ];
  },
  ViewContent: ({ list = '(not set)', origin, ...params }) => {
    return [
      [
        [
          {
            event: 'viewContent',
            ...(origin && { origin }),
            ecommerce: {
              detail: {
                actionField: { list },
                products: [paramsMapper(params, channelParams)],
              },
            },
          },
        ],
      ],
    ];
  },
  AddToCart: ({ origin, ...params }) => {
    return [
      [
        [
          {
            event: 'addToCart',
            ...(origin && { origin }),
            ecommerce: {
              add: { products: [paramsMapper(params, channelParams)] },
            },
          },
        ],
      ],
    ];
  },
  RemoveFromCart: ({ origin, ...params }) => {
    return [
      [
        [
          {
            event: 'removeFromCart',
            ...(origin && { origin }),
            ecommerce: {
              remove: { products: [paramsMapper(params, channelParams)] },
            },
          },
        ],
      ],
    ];
  },
  InitiateCheckout: ({ contents = [], origin, option }) => {
    const products = [...contents];
    const actionField = Object.assign({}, option && { option });
    return [
      [
        [
          {
            event: 'checkout',
            ...(origin && { origin }),
            ecommerce: { checkout: { actionField, products } },
          },
        ],
      ],
    ];
  },
  StartPayment: ({ origin }) => {
    const actionField = { step: 1 };
    return [
      [
        [
          {
            event: 'checkoutOption',
            ...(origin && { origin }),
            ecommerce: { checkout_option: { actionField } },
          },
        ],
      ],
    ];
  },
  AddPaymentInfo: ({ origin, option }) => {
    const actionField = Object.assign({ step: 2 }, option && { option });
    return [
      [
        [
          {
            event: 'checkoutOption',
            ...(origin && { origin }),
            ecommerce: { checkout_option: { actionField } },
          },
        ],
      ],
    ];
  },
  CheckoutStep: ({ step = 3, origin, option }) => {
    const actionField = Object.assign({ step }, option && { option });
    return [
      [
        [
          {
            event: 'checkoutOption',
            ...(origin && { origin }),
            ecommerce: { checkout_option: { actionField } },
          },
        ],
      ],
    ];
  },
  Purchase: ({ contents = [], origin, ...params }) => {
    const products = [...contents];
    const actionField = {};
    const mappedParams = paramsMapper(params, channelParams);
    [
      'id',
      'affiliation',
      'revenue',
      'tax',
      'shipping',
      'coupon',
      'buyer_id',
      'buyer_email',
    ].forEach(
      (param) =>
        mappedParams[param] && (actionField[param] = mappedParams[param]),
    );
    return [
      [
        [
          {
            event: 'purchase',
            ...(origin && { origin }),
            ecommerce: { purchase: { actionField, products } },
          },
        ],
      ],
    ];
  },
  Lead: () => {
    return [[[{ event: 'lead' }]]];
  },
  Schedule: (params) => {
    return [[[{ event: 'customEvent', ...params }]]];
  },
  CustomEvent: ({ event = 'customEvent', ...params }) => {
    return [[[{ event, ...params }]]];
  },
};
